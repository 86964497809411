

































































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';

@Component({
    components: {  }
})
export default class Results extends ListBase {
    response: any = 0;

    columns = [
        { value: 'index', text: '#', type: 'text', sortable: false },
        { value: 'code', text: 'Nro Matricula', type: 'text', sortable: false },
        { value: 'lawyer', text: 'Agremiado', type: 'text', sortable: false },
        { value: 'hour', text: 'Hora de Votacion', type: 'text', sortable: false },
        { value: 'response', text: 'Eligio', type: 'text', sortable: false }
    ];

    get option() {
        return this.$store.state.vote.option;
    }
    get loading() {
        return this.$store.state.vote.loading;
    }
    get list() {
        let list = [];
        let vm = this;
        this.$store.state.vote.list.forEach((item, index) => {
            if (vm.totals[vm.response].id == item.response_id) {
                item.index = index + 1;
                list.push(item);
            }
        });
        return list;
    }
    get totals() {
        let options = Util.abp.list.getList('T_RESPONSE');
        let items = [];
        
        options.forEach(item => {
            let amount = this.$store.state.vote.list.filter(opt => { return opt.response_id == item.id }).length;
            let icon = (item.value == 1 ? 'thumb_up' : (item.value == 2 ? 'thumb_down' : 'hourglass_full'));
            let color = (item.value == 1 ? 'cyan' : (item.value == 2 ? 'teal' : 'primary'));
            items.push({ name: item.name, icon: icon, color: color, amount: amount, id: item.id});
        });

        return items;
    }

    openVotes() {
        Util.toRoute(this, 'vote');
    }
    
    async mounted() {
        if (this.option.id == null) this.openVotes();
        let result = await this.$store.dispatch({
            type: 'vote/getResults',
            data: { option: this.option.id }
        });
    }
}